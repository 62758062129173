import useAxiosPrivate from "./useAxiosPrivate";

const usePrivateAPIClient = (baseURL, endpoint, isWithCredentials) => {
  const axiosPrivate = useAxiosPrivate(baseURL, isWithCredentials);

  const getAll = async (config = {}) => {
    const response = await axiosPrivate.get(endpoint, config);
    return response.data;
  };

  const getById = async (id, config = {}) => {
    const response = await axiosPrivate.get(`${endpoint}/${id}`, config);
    return response.data;
  };

  const deleteById = async (id, config = {}) => {
    const response = await axiosPrivate.delete(`${endpoint}/${id}`, config);
    return response.data;
  };

  const create = async (
    data = {},
    config = { headers: { "Content-Type": "application/json" } }
  ) => {
    const response = await axiosPrivate.post(endpoint, data, config);
    return response.data;
  };

  const update = async (
    id,
    data,
    config = { headers: { "Content-Type": "application/json" } }
  ) => {
    const response = await axiosPrivate.put(`${endpoint}/${id}`, data, config);
    return response.data;
  };

  const customAction = async (
    id,
    action,
    data,
    config = { headers: { "Content-Type": "application/json" } }
  ) => {
    const response = await axiosPrivate.put(
      `${endpoint}/${id}/${action}`,
      data,
      config
    );
    return response.data;
  };
  return { getAll, getById, deleteById, create, update, customAction };
};

export default usePrivateAPIClient;
