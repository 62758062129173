import React, { useState } from "react";
import { Link } from "react-router-dom";
import "react-datepicker/dist/react-datepicker.css";
import Table from "../../core/pagination/datatable";
import withReactContent from "sweetalert2-react-content";
import Swal from "sweetalert2";
import Breadcrumbs from "../../core/breadcrumbs";
import useDeleteExpense from "./../../core/hooks/useDeleteExpense";
import useGetExpense from "../../core/hooks/useGetExpense";
import EditExpense from "../../core/modals/inventory/editexpense";
import AddExpense from "../../core/modals/inventory/addexpense";
import useAuth from "../../core/hooks/useAuth";
import useGetExpenseStatuses from "../../core/hooks/useGetExpenseStatuses";
import { arePermissionsAllowed } from "../../Router/permissionChecker";
import { normalizeErrorMessage } from "../../utils/errorUtils";
import { useQueryClient } from "@tanstack/react-query";
import { EXPENSES_QUERY_KEY } from "../../core/queries/queryKeys";
import useApproveExpense from "../../core/hooks/useApproveExpense";
import useDeclineExpense from "../../core/hooks/useDeclineExpense";

const ExpensesList = () => {
  const queryClient = useQueryClient();
  const { auth } = useAuth();

  const userPermissions = auth.permissions || {};

  const isUpdateAllowed = arePermissionsAllowed(
    { EXPENSE: ["READ"] },
    userPermissions
  );

  const isDeleteAllowed = arePermissionsAllowed(
    { EXPENSE: ["READ"] },
    userPermissions
  );

  const isApproveAllowed = arePermissionsAllowed(
    { EXPENSE: ["APPROVE"] },
    userPermissions
  );

  const isDeclineAllowed = arePermissionsAllowed(
    { EXPENSE: ["DECLINE"] },
    userPermissions
  );

  const [currentRecord, setCurrentRecord] = useState();

  const deleteExpense = useDeleteExpense();
  const approveExpense = useApproveExpense();
  const declineExpense = useDeclineExpense();

  const { data: expenseData } = useGetExpense();
  const { data: statusData } = useGetExpenseStatuses();

  const expenses =
    expenseData?.data?.expenses?.map((expense) => {
      const status = statusData?.data?.expenseCategoryStatuses?.find(
        (entry) => entry?.id == expense?.statusId
      );
      return {
        ...expense,
        status: status ? status?.name : "Unknown",
      };
    }) || [];

  const MySwal = withReactContent(Swal);

  const showDeleteConfirmationAlert = (handleClickConfirm) => {
    MySwal.fire({
      title: "Are you sure?",
      text: "You won't be able to revert this!",
      showCancelButton: true,
      confirmButtonColor: "#00ff00",
      confirmButtonText: "Yes, delete it!",

      cancelButtonColor: "#ff0000",
      cancelButtonText: "Cancel",
    }).then((result) => {
      if (result.isConfirmed) {
        handleClickConfirm();
      } else {
        MySwal.close();
      }
    });
  };

  const handleDeleteConfirm = (id) => {
    deleteExpense.mutate(
      { id, userId: auth.userId },
      {
        onSuccess: () => {
          MySwal.fire({
            title: "Deleted!",
            text: "The meal category has been deleted.",
            icon: "success",
            confirmButtonText: "OK",
          }).then(() => {
            queryClient.invalidateQueries([EXPENSES_QUERY_KEY]);
          });
        },
        onError: (error) => {
          MySwal.fire({
            title: "Not Deleted!",
            text: normalizeErrorMessage(error),
            icon: "error",
            confirmButtonText: "OK",
          });
        },
      }
    );
  };

  const showApproveConfirmationAlert = (handleClickConfirm) => {
    MySwal.fire({
      title: "Are you sure?",
      text: "You won't be able to revert this!",
      showCancelButton: true,
      confirmButtonColor: "#00ff00",
      confirmButtonText: "Yes, approve it!",

      cancelButtonColor: "#ff0000",
      cancelButtonText: "Cancel",
    }).then((result) => {
      if (result.isConfirmed) {
        handleClickConfirm();
      } else {
        MySwal.close();
      }
    });
  };

  const handleApproveConfirm = (id) => {
    const data = { userId: auth.userId };
    approveExpense.mutate(
      { id, data },
      {
        onSuccess: () => {
          MySwal.fire({
            title: "Approved!",
            text: "The expense has been approved.",
            icon: "success",
            confirmButtonText: "OK",
          }).then(() => {
            queryClient.invalidateQueries([EXPENSES_QUERY_KEY]);
          });
        },
        onError: (error) => {
          MySwal.fire({
            title: "Not Approved!",
            text: normalizeErrorMessage(error),
            icon: "error",
            confirmButtonText: "OK",
          });
        },
      }
    );
  };

  const showDeclineConfirmationAlert = (handleClickConfirm) => {
    MySwal.fire({
      title: "Are you sure?",
      text: "You won't be able to revert this!",
      showCancelButton: true,
      confirmButtonColor: "#00ff00",
      confirmButtonText: "Yes, decline it!",

      cancelButtonColor: "#ff0000",
      cancelButtonText: "Cancel",
    }).then((result) => {
      if (result.isConfirmed) {
        handleClickConfirm();
      } else {
        MySwal.close();
      }
    });
  };

  const handleDeclineConfirm = (id) => {
    const data = { userId: auth.userId };
    declineExpense.mutate(
      { id, data },
      {
        onSuccess: () => {
          MySwal.fire({
            title: "Declined!",
            text: "The expense has been declined.",
            icon: "success",
            confirmButtonText: "OK",
          }).then(() => {
            queryClient.invalidateQueries([EXPENSES_QUERY_KEY]);
          });
        },
        onError: (error) => {
          MySwal.fire({
            title: "Not Declined!",
            text: normalizeErrorMessage(error),
            icon: "error",
            confirmButtonText: "OK",
          });
        },
      }
    );
  };

  const actionColumn =
    isUpdateAllowed || isDeleteAllowed
      ? {
          title: "Actions",
          dataIndex: "actions",
          key: "actions",
          render: (text, record) => (
            <div className="action-table-data">
              <div className="edit-delete-action">
                {isUpdateAllowed &&
                  record.status != "Approved" &&
                  record.status != "Rejected" && (
                    <Link
                      className="me-2 p-2 mb-0"
                      data-bs-toggle="modal"
                      data-bs-target="#edit-units"
                      onClick={() => setCurrentRecord(record)}
                    >
                      <i data-feather="edit" className="feather-edit"></i>
                    </Link>
                  )}
                {isDeleteAllowed &&
                  record.status != "Approved" &&
                  record.status != "Rejected" && (
                    <Link
                      className="me-3 confirm-text p-2 mb-0"
                      to="#"
                      onClick={() =>
                        showDeleteConfirmationAlert(() =>
                          handleDeleteConfirm(record.id)
                        )
                      }
                    >
                      <i data-feather="trash-2" className="feather-trash-2"></i>
                    </Link>
                  )}
                {isApproveAllowed &&
                  record.status != "Approved" &&
                  record.status != "Rejected" && (
                    <Link
                      className="me-2 p-2 mb-0"
                      to="#"
                      onClick={() =>
                        showApproveConfirmationAlert(() =>
                          handleApproveConfirm(record.id)
                        )
                      }
                    >
                      <i
                        data-feather="check-circle"
                        className="feather-check-circle"
                      ></i>
                    </Link>
                  )}
                {isDeclineAllowed &&
                  record.status != "Approved" &&
                  record.status != "Rejected" && (
                    <Link
                      className="me-2 p-2 mb-0"
                      to="#"
                      onClick={() =>
                        showDeclineConfirmationAlert(() =>
                          handleDeclineConfirm(record.id)
                        )
                      }
                    >
                      <i
                        data-feather="x-circle"
                        className="feather-x-circle"
                      ></i>
                    </Link>
                  )}
              </div>
            </div>
          ),
        }
      : {};

  const baseColumn = [
    {
      title: "Expense for",
      dataIndex: "expenseFor",
      sorter: (a, b) => a.expenseFor.length - b.expenseFor.length,
    },
    {
      title: "Status",
      dataIndex: "status",
      render: (text) => (
        <span
          className={`badge ${
            text === "Approved" ? "badge-linesuccess" : "badge-linedanger"
          }`}
        >
          {text}
        </span>
      ),
      sorter: (a, b) => a.status.length - b.status.length,
    },
    {
      title: "Amount",
      dataIndex: "amount",
      sorter: (a, b) => a.amount.length - b.amount.length,
    },
    {
      title: "Description",
      dataIndex: "description",
      sorter: (a, b) => a.description.length - b.description.length,
    },
    {
      title: "Date",
      dataIndex: "date",
      sorter: (a, b) => a.date.length - b.date.length,
    },
  ];

  const columns = [...baseColumn, actionColumn];

  const handleSearch = (e) => {
    setSearchText(e.target.value);
  };
  const [searchText, setSearchText] = useState("");

  return (
    <div>
      <div className="page-wrapper">
        <div className="content">
          <Breadcrumbs
            maintitle="Expense List "
            subtitle="Manage Your Expense"
            addButton="Add New Expense"
          />
          {/* /product list */}
          <div className="card table-list-card">
            <div className="card-body">
              <div className="table-top">
                <div className="search-set">
                  <div className="search-input">
                    <input
                      type="text"
                      placeholder="Search"
                      className="form-control form-control-sm formsearch"
                      aria-controls="DataTables_Table_0"
                      value={searchText}
                      onChange={handleSearch}
                    />
                    <Link to className="btn btn-searchset">
                      <i data-feather="search" className="feather-search" />
                    </Link>
                  </div>
                </div>
              </div>
              <div className="table-responsive">
                <Table columns={columns} dataSource={expenses} />
              </div>
            </div>
          </div>
          {/* /product list */}
        </div>
      </div>
      {/* Add Expense */}
      <AddExpense />
      {/* /Add Expense */}

      {/* Edit Expense */}
      <EditExpense currentRecord={currentRecord} />
      {/* /Edit Expense */}
    </div>
  );
};

export default ExpensesList;
