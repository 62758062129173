import React from "react";
import {
  PDFViewer,
  Document,
  Page,
  View,
  Text,
  StyleSheet,
  Image,
  Link,
} from "@react-pdf/renderer";
import { useLocation } from "react-router-dom";

const SalesVoucher = () => {
  const location = useLocation();
  const { transactionInfo, documentType } = location.state; // Assuming transactionInfo and documentType are passed from previous state

  const isInvoice = documentType === "invoice";
  const isReceipt = documentType === "receipt";
  const isPo = documentType === "purchase-order";
  const isOc = documentType === "order-confirmation";

  const title = isInvoice
    ? "Invoice"
    : isReceipt
    ? "Receipt"
    : isPo
    ? "Purchasing Order"
    : isOc
    ? "Order Confirmation"
    : "Unknown Document";

  const amountLabel =
    isInvoice || isPo || isOc ? "Amount Payable" : "Amount Paid";

  return (
    <React.Fragment>
      <div className="page-wrapper">
        <div className="content">
          <PDFViewer style={{ width: "100%", height: "80vh" }}>
            <Document>
              <Page
                size={{ width: 80 * 2.83465, height: "auto" }}
                style={styles.page}
              >
                <View style={styles.section}>
                  <View style={styles.header}>
                    <Image src="assets/img/logo.png" style={styles.logo} />
                  </View>
                  <View style={styles.info}>
                    <Text style={styles.infoText}>Diamond Hotel Kipe</Text>
                    <Text style={styles.infoText}>
                      Phone Number: +224 629696944 / 620354668
                    </Text>
                    <Text style={styles.infoText}>
                      Email:
                      <Text style={styles.infoLink}>
                        <Link src="mailto:info@diamondhotelkipe.com">
                          info@diamondhotelkipe.com
                        </Link>
                      </Text>
                    </Text>
                  </View>
                  <View style={styles.document}>
                    <View style={styles.titleContainer}>
                      <Text style={styles.documentTitle}>{title}</Text>
                      <View style={styles.dashedLineTitle} />
                    </View>
                    <View style={styles.documentDetails}>
                      <View style={styles.documentDetail}>
                        <Text>{isPo ? "Supplier Name:" : "Name:"}</Text>
                        <Text>{transactionInfo?.name}</Text>
                      </View>
                      <View style={styles.documentDetail}>
                        <Text>
                          {isInvoice || isPo || isOc
                            ? "Document No:"
                            : "Receipt No:"}
                        </Text>
                        <Text>{transactionInfo?.transactionId}</Text>
                      </View>
                      <View style={styles.documentDetail}>
                        <Text>Date:{transactionInfo?.date}</Text>
                      </View>
                    </View>
                  </View>
                  <View style={styles.table}>
                    <View style={styles.tableHeaderContainer}>
                      <View style={styles.dashedLine} />
                      <View style={styles.tableHeaderRow}>
                        <Text style={styles.tableHeader}># Item</Text>
                        <Text style={styles.tableHeader}>Price</Text>
                        <Text style={styles.tableHeader}>Qty</Text>
                        <Text style={[styles.tableHeader, styles.textEnd]}>
                          Total
                        </Text>
                      </View>
                      <View style={styles.dashedLine} />
                    </View>
                    {transactionInfo?.orderItems?.map((item, key) => (
                      <View key={key} style={styles.tableRow}>
                        <Text style={styles.tableCell}>{item?.name}</Text>
                        <Text style={styles.tableCell}>{item?.unitPrice}</Text>
                        <Text style={styles.tableCell}>
                          {item?.quantitySold}
                        </Text>
                        <Text style={[styles.tableCell, styles.textEnd]}>
                          {item?.lineTotalAmount}
                        </Text>
                      </View>
                    ))}
                    <View style={styles.tableFooterContainer}>
                      <View style={styles.dashedLineAboveFooter} />
                      <View style={styles.tableFooter}>
                        <Text>Sub Total:</Text>
                        <Text
                          style={styles.textEnd}
                        >{`GNF ${transactionInfo?.totalAmount}`}</Text>
                      </View>
                      <View style={styles.spaceBetweenFooter} />
                      <View style={styles.tableFooter}>
                        <Text>{amountLabel}:</Text>
                        <Text
                          style={styles.textEnd}
                        >{`GNF ${transactionInfo?.totalAmount}`}</Text>
                      </View>
                      <View style={styles.dashedLineAboveBar} />
                    </View>
                  </View>
                  <View style={styles.documentBar}>
                    <Text>
                      {isInvoice
                        ? "Thank You For Your Business"
                        : isPo
                        ? "Thank you for your prompt service. We look forward to continuing our partnership"
                        : "Thank You For Your Payment"}
                    </Text>
                  </View>
                </View>
              </Page>
            </Document>
          </PDFViewer>
        </div>
      </div>
    </React.Fragment>
  );
};

const styles = StyleSheet.create({
  page: {
    flexDirection: "column",
    padding: 5,
  },
  section: {
    margin: 5,
    padding: 10,
  },
  header: {
    marginBottom: 20,
    textAlign: "center",
    alignItems: "center",
  },
  logo: {
    width: 80,
    height: 24,
  },
  info: {
    marginBottom: 20,
    textAlign: "center",
    fontSize: 9,
  },
  infoText: {
    marginBottom: 5,
  },
  infoLink: {
    color: "blue",
    textDecoration: "underline",
  },
  document: {
    marginBottom: 2,
    textAlign: "center",
  },
  titleContainer: {
    marginBottom: 10,
  },
  documentTitle: {
    fontSize: 12,
    fontWeight: "bold",
    marginBottom: 5,
  },
  dashedLineTitle: {
    borderBottom: "1px dashed black",
    width: "100%",
    marginVertical: -10,
    marginBottom: 10,
  },
  dashedLine: {
    borderBottom: "1px dashed black",
    marginVertical: 5,
    width: "100%",
  },
  dashedLineAboveFooter: {
    borderBottom: "1px dashed black",
    marginVertical: 10,
    width: "100%",
  },
  dashedLineAboveBar: {
    borderBottom: "1px dashed black",
    marginVertical: 10,
    width: "100%",
  },
  documentDetails: {
    flexDirection: "row",
    flexWrap: "wrap", // Add this to allow wrapping
    justifyContent: "space-between",
    marginBottom: 10,
    fontSize: 8,
  },
  documentDetail: {
    flexDirection: "row",
    flexWrap: "wrap", // Add this to allow wrapping
    marginBottom: 5,
    width: "50%", // Optional: control the width to manage wrapping
  },
  table: {
    marginBottom: 20,
  },
  tableHeaderContainer: {
    marginBottom: 10,
  },
  tableHeaderRow: {
    flexDirection: "row",
    justifyContent: "space-between",
    marginVertical: 5,
    fontSize: 8,
  },
  tableHeader: {
    fontWeight: "bold",
    width: "25%",
  },
  tableRow: {
    flexDirection: "row",
    justifyContent: "space-between",
    marginBottom: 5,
    fontSize: 8,
  },
  tableCell: {
    width: "25%",
  },
  textEnd: {
    textAlign: "right",
  },
  tableFooterContainer: {
    marginTop: 10,
  },
  tableFooter: {
    flexDirection: "row",
    justifyContent: "space-between",
    marginBottom: 5,
    fontSize: 8,
  },
  spaceBetweenFooter: {
    marginBottom: 15,
  },
  documentBar: {
    textAlign: "center",
    margin: 0,
    padding: 0,
    fontSize: 8,
  },
});

export default SalesVoucher;
