import React, { useState, useEffect } from "react";
import { Outlet } from "react-router-dom";
import useRefreshToken from "./../core/hooks/useRefreshToken";
import useAuth from "../core/hooks/useAuth";
import { jwtDecode } from "jwt-decode";
const PersistLogin = () => {
  const [isLoading, setIsLoading] = useState(true);
  const { mutateAsync: refreshToken } = useRefreshToken();
  const { auth, setAuth } = useAuth();

  const isPersist = localStorage.getItem("DHK_RETAIL_REMEMBER_ME") === "true";
  const storedAccessToken = localStorage.getItem("DHK_RETAIL_ACCESS_TOKEN");

  const isTokenExpired = (token) => {
    const decodedToken = jwtDecode(token);
    return decodedToken.exp * 1000 < Date.now();
  };

  useEffect(() => {
    let isMounted = true;

    const verifyRefreshToken = async () => {
      try {
        const response = await refreshToken({});
        if (response?.accessToken) {
          const decodedJwt = jwtDecode(response.accessToken);
          const { username, permissions, role, userId } = decodedJwt.userInfo;
          setAuth((prev) => ({
            ...prev,
            username,
            permissions,
            role,
            accessToken: response.accessToken,
            userId,
          }));
        } else {
          console.error("No access token found in response");
        }
      } catch (err) {
        console.error("Failed to refresh token:", err);
      } finally {
        if (isMounted) setIsLoading(false);
      }
    };

    if (!auth?.accessToken && isPersist) {
      if (storedAccessToken) {
        if (isTokenExpired(storedAccessToken)) {
          verifyRefreshToken();
        } else {
          // Restore the auth state from localStorage
          const decodedJwt = jwtDecode(storedAccessToken);
          const { username, permissions, role, userId } = decodedJwt.userInfo;
          setAuth({
            username,
            permissions,
            role,
            accessToken: storedAccessToken,
            userId,
          });
          setIsLoading(false);
        }
      } else {
        verifyRefreshToken();
      }
    } else {
      setIsLoading(false);
    }

    return () => {
      isMounted = false;
    };
  }, [auth?.accessToken, isPersist, refreshToken, setAuth, storedAccessToken]);

  return (
    <>{!isPersist ? <Outlet /> : isLoading ? <p>Loading...</p> : <Outlet />}</>
  );
};

export default PersistLogin;
