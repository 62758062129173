import { useMutation } from "@tanstack/react-query";
import usePrivateAPIClient from "../services/usePrivateAPIClient";
import getEnvVariable from "../../getEnvVariable";

const baseUrl = getEnvVariable("REACT_APP_RETAIL_API_BASE_URL");

const useDeclineExpense = () => {
  const { customAction } = usePrivateAPIClient(
    baseUrl,
    "/api/v1/expenses",
    true
  );
  return useMutation({
    mutationFn: ({ id, data }) => customAction(id, "decline", data),
  });
};

export default useDeclineExpense;
