import { useQuery } from "@tanstack/react-query";
import usePrivateAPIClient from "../services/usePrivateAPIClient";
import getEnvVariable from "../../getEnvVariable";
const baseUrl = getEnvVariable("REACT_APP_RETAIL_API_BASE_URL");

const useGetExpenseCategories = () => {
  const { getAll } = usePrivateAPIClient(
    baseUrl,
    "/api/v1/expense-categories",
    true
  );

  const result = useQuery({
    queryKey: ["expensCategory"],
    queryFn: () => getAll(),
  });

  return result;
};

export default useGetExpenseCategories;
