import React from "react";
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { all_routes } from "../../../Router/all_routes";
import useUserLogout from "./../../../core/hooks/useUserLogout";
import useAuth from "../../../core/hooks/useAuth";

const Logout = () => {
  const navigate = useNavigate();
  const logout = useUserLogout();
  const { setAuth } = useAuth();

  const route = all_routes;

  useEffect(() => {
    const handleLogout = async () => {
      try {
        logout.mutate(); // Invoke the logout mutation function
        localStorage.removeItem("DHK_RETAIL_ACCESS_TOKEN");
        localStorage.removeItem("DHK_RETAIL_REMEMBER_ME");
        navigate(route.signin); // Redirect to login page
      } catch (error) {
        console.error("Logout failed:", error);
        // Handle logout failure here if needed
      }
    };

    handleLogout();

    // Cleanup function: unset auth state and abort any pending requests if necessary
    return () => {
      setAuth({});
      // Optionally: cancel any pending requests or cleanup async operations
    };
  }, [navigate, logout, setAuth]); // Ensure dependencies are included in the array

  return <></>; // or any loading indicator if needed
};

export default Logout;
