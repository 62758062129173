import React, { useEffect, useState } from "react";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import { Link } from "react-router-dom";
import {
  ChevronUp,
  PlusCircle,
  RotateCcw,
} from "feather-icons-react/build/IconComponents";
import { setToogleHeader } from "../../core/redux/action";
import { useDispatch, useSelector } from "react-redux";
import withReactContent from "sweetalert2-react-content";
import Swal from "sweetalert2";
import Table from "../../core/pagination/datatable";
import AddPurchases from "../../core/modals/purchases/addpurchases";
import useGetPurchasingOrders from "../../core/hooks/useGetPurchasingOrders";
import useGetSuppliers from "../../core/hooks/useGetSuppliers";
import PrintPurchaseOrder from "../../core/modals/purchases/printpurchaseorder";
import useGetBulkProducts from "../../core/hooks/useGetBulkProducts";
import useDeletePurchaseOrder from "../../core/hooks/useDeletePurchaseOrder";
import useReceivePurchasingOrder from "../../core/hooks/useReceivePurchasingOrder";
import useAuth from "../../core/hooks/useAuth";
import { arePermissionsAllowed } from "../../Router/permissionChecker";
import EditPurchase from "../../core/modals/purchases/editpurchase";
import { useQueryClient } from "@tanstack/react-query";
import { PURCHASE_ORDER_QUERY_KEY } from "../../core/queries/queryKeys";
import useGetPurchaseOrderStatuses from "./../../core/hooks/useGetPurchaseOrderStatuses";
import Modal from "./../../core/modals/Modal";
import { normalizeErrorMessage } from "../../utils/errorUtils";
import useDeclinePurchase from "../../core/hooks/useDeclinePurchase";
import useApprovePurchase from "../../core/hooks/useApprovePurchase";

const PurchasesList = () => {
  const queryClient = useQueryClient();

  const { auth } = useAuth();

  const userPermissions = auth.permissions || {};

  const isUpdateAllowed = arePermissionsAllowed(
    { PURCHASE: ["UPDATE"] },
    userPermissions
  );

  const isDeleteAllowed = arePermissionsAllowed(
    { PURCHASE: ["DELETE"] },
    userPermissions
  );

  // const isViewPOAllowed = arePermissionsAllowed(
  //   { PURCHASE: ["READ"] },
  //   userPermissions
  // );

  const isReceivePOAllowed = arePermissionsAllowed(
    { PURCHASE: ["UPDATE"] },
    userPermissions
  );

  const isApproveAllowed = arePermissionsAllowed(
    { PURCHASE: ["APPROVE"] },
    userPermissions
  );

  const isDeclineAllowed = arePermissionsAllowed(
    { PURCHASE: ["DECLINE"] },
    userPermissions
  );

  const declinePurchase = useDeclinePurchase();
  const approvePurchase = useApprovePurchase();
  const showApproveConfirmationAlert = (handleClickConfirm) => {
    MySwal.fire({
      title: "Are you sure?",
      text: "You won't be able to revert this!",
      showCancelButton: true,
      confirmButtonColor: "#00ff00",
      confirmButtonText: "Yes, approve it!",

      cancelButtonColor: "#ff0000",
      cancelButtonText: "Cancel",
    }).then((result) => {
      if (result.isConfirmed) {
        handleClickConfirm();
      } else {
        MySwal.close();
      }
    });
  };

  const handleApproveConfirm = (id) => {
    const data = { userId: auth.userId };
    approvePurchase.mutate(
      { id, data },
      {
        onSuccess: () => {
          MySwal.fire({
            title: "Approved!",
            text: "The purchase has been approved.",
            icon: "success",
            confirmButtonText: "OK",
          }).then(() => {
            queryClient.invalidateQueries([PURCHASE_ORDER_QUERY_KEY]);
          });
        },
        onError: (error) => {
          MySwal.fire({
            title: "Not Approved!",
            text: normalizeErrorMessage(error),
            icon: "error",
            confirmButtonText: "OK",
          });
        },
      }
    );
  };

  const showDeclineConfirmationAlert = (handleClickConfirm) => {
    MySwal.fire({
      title: "Are you sure?",
      text: "You won't be able to revert this!",
      showCancelButton: true,
      confirmButtonColor: "#00ff00",
      confirmButtonText: "Yes, decline it!",

      cancelButtonColor: "#ff0000",
      cancelButtonText: "Cancel",
    }).then((result) => {
      if (result.isConfirmed) {
        handleClickConfirm();
      } else {
        MySwal.close();
      }
    });
  };

  const handleDeclineConfirm = (id) => {
    const data = { userId: auth.userId };
    declinePurchase.mutate(
      { id, data },
      {
        onSuccess: () => {
          MySwal.fire({
            title: "Declined!",
            text: "The purchase has been declined.",
            icon: "success",
            confirmButtonText: "OK",
          }).then(() => {
            queryClient.invalidateQueries([PURCHASE_ORDER_QUERY_KEY]);
          });
        },
        onError: (error) => {
          MySwal.fire({
            title: "Not Declined!",
            text: normalizeErrorMessage(error),
            icon: "error",
            confirmButtonText: "OK",
          });
        },
      }
    );
  };

  const [activePurchaseOrder, setActivePurchaseOrder] = useState();

  const { data: purchasingOrderData } = useGetPurchasingOrders();
  const { data: suppliersData } = useGetSuppliers();
  const { data: productsData } = useGetBulkProducts();
  const deletePurchaseOrder = useDeletePurchaseOrder();
  const receivePurchasingOrder = useReceivePurchasingOrder();
  const [currentRecord, setCurrentRecord] = useState();
  const [modifiedPurchasingOrderData, setModifiedPurchasingOrderData] =
    useState([]);

  const [isPrintPurchaseOrderModalOpen, setIsPrintPurchaseOrderModalOpen] =
    useState(false);
  const [isApproved, setIsApproved] = useState(false);

  const { data: statusData, isSuccess: isStatusesSuccess } =
    useGetPurchaseOrderStatuses();

  useEffect(() => {
    if (
      purchasingOrderData &&
      suppliersData &&
      productsData &&
      statusData &&
      isStatusesSuccess
    ) {
      const statusLookup = statusData.data.statuses.reduce((acc, status) => {
        acc[status.id] = status.name;
        return acc;
      }, {});

      const modifiedData = purchasingOrderData?.data?.purchasingOrders?.map(
        (order) => {
          const supplier = suppliersData?.data?.suppliers?.find(
            (entry) => entry?.id == order?.supplierId
          );
          const updatedProductList = order?.productList?.map((pro) => {
            const product = productsData?.data?.products?.find(
              (entry) => entry?.id == pro?.productId
            );
            return {
              ...pro,
              name: product ? product?.name : "Unknown Product",
            };
          });
          return {
            ...order,
            supplierName: supplier ? supplier?.name : "Unknown Supplier",
            productList: updatedProductList,
            status: statusLookup[order?.statusId],
          };
        }
      );
      setModifiedPurchasingOrderData(modifiedData);
    }
  }, [
    purchasingOrderData,
    suppliersData,
    productsData,
    statusData,
    isStatusesSuccess,
  ]);

  const [searchText, setSearchText] = useState("");
  const handleSearch = (e) => {
    setSearchText(e.target.value);
  };

  const showDeleteConfirmationAlert = (handleClickConfirm) => {
    MySwal.fire({
      title: "Are you sure?",
      text: "You won't be able to revert this!",
      showCancelButton: true,
      confirmButtonColor: "#00ff00",
      confirmButtonText: "Yes, delete it!",

      cancelButtonColor: "#ff0000",
      cancelButtonText: "Cancel",
    }).then((result) => {
      if (result.isConfirmed) {
        handleClickConfirm();
      } else {
        MySwal.close();
      }
    });
  };

  const handleDeleteConfirm = (id) => {
    deletePurchaseOrder.mutate(
      { id, userId: auth.userId },
      {
        onSuccess: () => {
          MySwal.fire({
            title: "Deleted!",
            text: "The purchase order has been deleted.",
            icon: "success",
            confirmButtonText: "OK",
          }).then(() => {
            queryClient.invalidateQueries([PURCHASE_ORDER_QUERY_KEY]);
          });
        },
        onError: (error) => {
          MySwal.fire({
            title: "Not Deleted!",
            text: normalizeErrorMessage(error),
            icon: "error",
            confirmButtonText: "OK",
          });
        },
      }
    );
  };

  const showReceiveConfirmationAlert = (handleClickConfirm) => {
    MySwal.fire({
      title: "Are you sure?",
      text: "You won't be able to revert this!",
      showCancelButton: true,
      confirmButtonColor: "#00ff00",
      confirmButtonText: "Yes, receive it!",

      cancelButtonColor: "#ff0000",
      cancelButtonText: "Cancel",
    }).then((result) => {
      if (result.isConfirmed) {
        handleClickConfirm();
      } else {
        MySwal.close();
      }
    });
  };

  const handleReceiveConfirm = (id) => {
    receivePurchasingOrder.mutate(
      { id, notes: "All Received", userId: auth.userId },
      {
        onSuccess: () => {
          MySwal.fire({
            title: "Received!",
            text: "The purchase order has been received.",
            icon: "success",
            confirmButtonText: "OK",
          }).then(() => {
            queryClient.invalidateQueries([PURCHASE_ORDER_QUERY_KEY]);
          });
        },
        onError: (error) => {
          MySwal.fire({
            title: "Not Received!",
            text: normalizeErrorMessage(error),
            icon: "error",
            confirmButtonText: "OK",
          });
        },
      }
    );
  };

  const actionColumn =
    isUpdateAllowed || isDeleteAllowed
      ? {
          title: "Actions",
          dataIndex: "actions",
          key: "actions",
          render: (text, record) => (
            <div className="action-table-data">
              <div className="edit-delete-action">
                {isUpdateAllowed &&
                  record.status != "Approved" &&
                  record.status != "Received" &&
                  record.status != "Rejected" && (
                    <Link
                      className="me-2 p-2 mb-0"
                      data-bs-toggle="modal"
                      data-bs-target="#edit-units"
                      onClick={() => setCurrentRecord(record)}
                    >
                      <i data-feather="edit" className="feather-edit"></i>
                    </Link>
                  )}
                {isDeleteAllowed &&
                  record.status != "Approved" &&
                  record.status != "Received" &&
                  record.status != "Rejected" && (
                    <Link
                      className="me-3 confirm-text p-2 mb-0"
                      to="#"
                      onClick={() =>
                        showDeleteConfirmationAlert(() =>
                          handleDeleteConfirm(record.poId)
                        )
                      }
                    >
                      <i data-feather="trash-2" className="feather-trash-2"></i>
                    </Link>
                  )}

                <Link
                  className="me-2 p-2"
                  onClick={() => {
                    setActivePurchaseOrder(record);
                    setIsApproved(record.status === "Approved");
                    setIsPrintPurchaseOrderModalOpen(true);
                  }}
                >
                  <i data-feather="eye" className="feather-eye"></i>
                </Link>

                {isReceivePOAllowed &&
                  record.status != "Received" &&
                  record.status === "Approved" &&
                  record.status != "Rejected" && (
                    <Link
                      className="me-2 p-2"
                      onClick={() =>
                        showReceiveConfirmationAlert(() =>
                          handleReceiveConfirm(record.poId)
                        )
                      }
                    >
                      <i data-feather="package" className="feather-package"></i>
                    </Link>
                  )}
                {isApproveAllowed &&
                  record.status != "Approved" &&
                  record.status != "Received" &&
                  record.status != "Rejected" && (
                    <Link
                      className="me-2 p-2 mb-0"
                      to="#"
                      onClick={() =>
                        showApproveConfirmationAlert(() =>
                          handleApproveConfirm(record.poId)
                        )
                      }
                    >
                      <i
                        data-feather="check-circle"
                        className="feather-check-circle"
                      ></i>
                    </Link>
                  )}
                {isDeclineAllowed &&
                  record.status != "Approved" &&
                  record.status != "Received" &&
                  record.status != "Rejected" && (
                    <Link
                      className="me-2 p-2 mb-0"
                      to="#"
                      onClick={() =>
                        showDeclineConfirmationAlert(() =>
                          handleDeclineConfirm(record.poId)
                        )
                      }
                    >
                      <i
                        data-feather="x-circle"
                        className="feather-x-circle"
                      ></i>
                    </Link>
                  )}
              </div>
            </div>
          ),
        }
      : {};
  const getBadgeClass = (status) => {
    switch (status) {
      case "Pending":
        return "badge-bgdanger";
      case "Approved":
        return "badge badges-warning";
      case "Received":
        return "status-badge";
      default:
        return "badge-bgdanger";
    }
  };
  const baseColumns = [
    {
      title: "PoId",
      dataIndex: "poId",
      sorter: (a, b) => a.poId - b.poId,
    },
    {
      title: "Supplier",
      dataIndex: "supplierName",
      sorter: (a, b) => a.supplierName - b.supplierName,
    },
    {
      title: "Total Amount",
      dataIndex: "totalAmount",
      sorter: (a, b) => a.totalAmount - b.totalAmount,
    },
    {
      title: "Date",
      dataIndex: "date",
      sorter: (a, b) => new Date(a.date) - new Date(b.date),
    },
    {
      title: "Status",
      dataIndex: "status",
      render: (text) => (
        <span className={`badges ${getBadgeClass(text)} }`}>{text}</span>
      ),
      sorter: (a, b) => a.status.localeCompare(b.status),
    },
  ];

  const columns = [...baseColumns, actionColumn];

  const dispatch = useDispatch();
  const data = useSelector((state) => state.toggle_header);

  const renderRefreshTooltip = (props) => (
    <Tooltip id="refresh-tooltip" {...props}>
      Refresh
    </Tooltip>
  );

  const renderCollapseTooltip = (props) => (
    <Tooltip id="refresh-tooltip" {...props}>
      Collapse
    </Tooltip>
  );

  const MySwal = withReactContent(Swal);

  return (
    <div>
      <div className="page-wrapper">
        <div className="content">
          <div className="page-header transfer">
            <div className="add-item d-flex">
              <div className="page-title">
                <h4>Purchase List</h4>
                <h6>Manage your purchases</h6>
              </div>
            </div>
            <ul className="table-top-head">
              <li>
                <OverlayTrigger placement="top" overlay={renderRefreshTooltip}>
                  <Link data-bs-toggle="tooltip" data-bs-placement="top">
                    <RotateCcw />
                  </Link>
                </OverlayTrigger>
              </li>
              <li>
                <OverlayTrigger placement="top" overlay={renderCollapseTooltip}>
                  <Link
                    data-bs-toggle="tooltip"
                    data-bs-placement="top"
                    id="collapse-header"
                    className={data ? "active" : ""}
                    onClick={() => {
                      dispatch(setToogleHeader(!data));
                    }}
                  >
                    <ChevronUp />
                  </Link>
                </OverlayTrigger>
              </li>
            </ul>
            <div className="d-flex purchase-pg-btn">
              <div className="page-btn">
                <Link
                  to="#"
                  className="btn btn-added"
                  data-bs-toggle="modal"
                  data-bs-target="#add-units"
                >
                  <PlusCircle className="me-2" />
                  Add New Purchase
                </Link>
              </div>
            </div>
          </div>
          {/* /product list */}
          <div className="card table-list-card">
            <div className="card-body">
              <div className="table-top">
                <div className="search-set">
                  <div className="search-input">
                    <Link to="#" className="btn btn-searchset">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width={24}
                        height={24}
                        viewBox="0 0 24 24"
                        fill="none"
                        stroke="currentColor"
                        strokeWidth={2}
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        className="feather feather-search"
                      >
                        <circle cx={11} cy={11} r={8} />
                        <line x1={21} y1={21} x2="16.65" y2="16.65" />
                      </svg>
                    </Link>
                    <div
                      id="DataTables_Table_0_filter"
                      className="dataTables_filter"
                    >
                      <label>
                        <input
                          type="search"
                          className="form-control form-control-sm"
                          placeholder="Search"
                          aria-controls="DataTables_Table_0"
                          value={searchText}
                          onChange={handleSearch}
                        />
                      </label>
                    </div>
                  </div>
                </div>
              </div>
              <div className="table-responsive product-list">
                {modifiedPurchasingOrderData && (
                  <Table
                    columns={columns}
                    dataSource={modifiedPurchasingOrderData}
                  />
                )}
              </div>
            </div>
          </div>
          {/* /product list */}
        </div>
      </div>
      <AddPurchases />

      <EditPurchase currentRecord={currentRecord} />

      {/* Purchase Order */}
      <Modal modalId="print-invoice" show={isPrintPurchaseOrderModalOpen}>
        <PrintPurchaseOrder
          purchaseOrderDetails={activePurchaseOrder}
          isApproved={isApproved}
          hide={() => setIsPrintPurchaseOrderModalOpen(false)}
        />
      </Modal>
      {/*  Purchase Order */}
    </div>
  );
};

export default PurchasesList;
