import axios from "axios";
import useRefreshToken from "../hooks/useRefreshToken";
import useAuth from "../hooks/useAuth";
import { useEffect } from "react";

const axiosPrivate = axios.create(); // Create instance once

const useAxiosPrivate = (baseURL, isWithCredentials) => {
  const { mutateAsync: refreshToken } = useRefreshToken();
  const { auth } = useAuth();

  useEffect(() => {
    axiosPrivate.defaults.baseURL = baseURL;
    axiosPrivate.defaults.withCredentials = isWithCredentials;

    const requestInterceptor = axiosPrivate.interceptors.request.use(
      (config) => {
        const token = auth?.accessToken;
        if (token && !config.headers["Authorization"]) {
          config.headers["Authorization"] = `Bearer ${token}`;
        }
        return config;
      },
      (error) => Promise.reject(error)
    );

    const responseInterceptor = axiosPrivate.interceptors.response.use(
      (response) => response,
      async (error) => {
        const prevRequest = error?.config;
        if (error?.response?.status === 403 && !prevRequest?.sent) {
          prevRequest.sent = true;
          try {
            const newTokenResponse = await refreshToken({});
            prevRequest.headers[
              "Authorization"
            ] = `Bearer ${newTokenResponse.accessToken}`;
            return axiosPrivate(prevRequest);
          } catch (refreshError) {
            return Promise.reject(refreshError);
          }
        }
        return Promise.reject(error);
      }
    );

    return () => {
      axiosPrivate.interceptors.request.eject(requestInterceptor);
      axiosPrivate.interceptors.response.eject(responseInterceptor);
    };
  }, [auth, baseURL, isWithCredentials, refreshToken]);

  return axiosPrivate;
};

export default useAxiosPrivate;
