import { useMutation } from "@tanstack/react-query";
import usePrivateAPIClient from "../services/usePrivateAPIClient";
import getEnvVariable from "../../getEnvVariable";

const baseUrl = getEnvVariable("REACT_APP_RETAIL_API_BASE_URL");

const useUpdateExpense = () => {
  const { update } = usePrivateAPIClient(baseUrl, "/api/v1/expenses", true);
  return useMutation({
    mutationFn: ({ id, data }) => update(id, data),
    enabled: (props) => Boolean(props.data),
  });
};

export default useUpdateExpense;
